<template>
  <div class="about-page-detail">
    <main>
      <div v-if="content" class="content" v-html="content"></div>
      <div v-else class="empty-style">
        <p>暂时还没有相关信息</p>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {
      content: ""
    };
  },
  created() {
    // 调取获取详情
    this.handleGetDetail();
  },
  methods: {
    handleGetDetail() {
      const type = 3;
      this.$api.getCopywriting({ type }).then(res => {
        console.log(res.data);
        if (res.data.code === 1) {
          console.log(res);
          this.content = res.data?.data?.content_html;
        } else {
          console.log(123456);
          this.$message.error(res.data.msg);
        }
      });
    }
  }
};
</script>

<style lang="scss">
.about-page-detail {
  width: 1280px;
  min-height: calc(100% - 60px);
  margin: 0 auto;
  padding: 50px 0px;
  color: #000000;
  .empty-style {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content {
    img {
      width: 100%;
      margin-bottom: 10px;
    }
    h1 {
      margin-top: 0;
      margin-bottom: 0.5em;
      color: #333333;
      font-weight: 500;
      font-size: 22px;
    }
    h2 {
      margin-top: 0;
      margin-bottom: 0.5em;
      color: #333333;
      font-weight: 500;
      font-size: 21px;
    }
    h3 {
      margin-top: 0;
      margin-bottom: 0.5em;
      color: #333333;
      font-weight: 500;
      font-size: 16px;
    }
    h4 {
      margin-top: 0;
      margin-bottom: 0.5em;
      color: #333333;
      font-weight: 500;
      font-size: 14px;
    }
    h5 {
      margin-top: 0;
      margin-bottom: 0.5em;
      color: #333333;
      font-weight: 500;
      font-size: 12px;
    }
    h6 {
      margin-top: 0;
      margin-bottom: 0.5em;
      color: #333333;
      font-weight: 500;
      font-size: 12px;
    }
    p {
      line-height: 1.5;
      color: #333333;
      margin-top: 0;
      margin-bottom: 1em;
    }
  }
}
</style>
